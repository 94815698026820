import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import styled, { css } from "styled-components";

export const AcceptPopupWrapper = styled.div`
  ${({ theme }) => css`
  `};
`;

export const AcceptPopupBody = styled.div`
  ${({ theme }) => css`
    padding: 24px;
    padding-top: 0
  `};
`;
export const StyledAcceptPopup = styled(Dialog)`
    ${({ theme }) => css`
        .k-window-titlebar{
            max-width: 600px;
            border-bottom: none;
            padding-left: 24px;
            padding-right: 24px;
            padding-right: ${theme['thm-space-rg']};
        }
        .k-window-content{
            max-width: 600px;
            padding: 0px;
        }
        .k-dialog-buttongroup{
            padding-left: 24px;
            padding-right: 24px;
        }
    `};
`;