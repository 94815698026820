import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { Session, Settings } from "@core-interface";
import { myProfileNavigation } from "./constant";
import { logoutUser, getSession, loginUrl } from "@core-const";
import { useDispatch, useSelector } from "react-redux";
import { getSettingsFromStore } from "src/core/core.selectors";
import { setSettings } from "@core-action";
import { Message } from "@localization";
import { PatientDefaultIcon } from "app-core-img";

import * as SC from "./myProfile.styled";
import {
    Avatar,
    Drawer,
    DrawerContent,
    DrawerItemProps,
} from "@progress/kendo-react-layout";

import { userRoleRender } from "app-core/styled/components_v3";

const MyProfilePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let location = useLocation();
    const status = location.pathname.split("/")[2];
    const [showNavi, setShowNavi] = useState(false);
    const [expanded, setExpanded] = useState<boolean>(true);
    const session: Session = getSession();
    const settings: Settings = useSelector(getSettingsFromStore);
    if (!session) {
        localStorage.setItem("redirectUrl", location.pathname);
    }

    const navigationPath = useCallback(
        ({ url, id }: any) => {
            setExpanded((prev) => !prev);
            if (id === "logout") {
                localStorage.setItem('manualLogout', 'true');
                logoutUser();
            }
            else if (id == "my_chats") window.open(url, "_blank");
            else {
                if (settings.isMobile) dispatch(setSettings({ showNavigation: false }));
                navigate(url, { replace: true });
            }
        },
        [navigate]
    );

    useEffect(() => {
        setShowNavi(settings.showNavigation);
    }, [settings.showNavigation]);

    const drawerItemContainer = (item: DrawerItemProps) => {
        if (item.id == 'logout') return <SC.NavigatorListItemConatainer className={'nav-item-logout'}>
            <SC.ListItemLogoutContainer className={'nav-item-logout-container'}>
                <Avatar
                    className={'nav-item-text'}
                    rounded="full"
                    type="image"
                    style={{ flexBasis: '40px', height: '40px' }}
                >
                    <img src={session.picture ? session.picture : `${PatientDefaultIcon}`} />
                </Avatar>
                <SC.ListItemLogoutProfilewrapper className={'nav-item-text'}>
                    <SC.LogoutNameContainer small>
                        {session.nickName}
                    </SC.LogoutNameContainer>
                    {userRoleRender(session.role)}
                </SC.ListItemLogoutProfilewrapper>
                <SC.ListItemLogoutIconContainer className={'nav-item-logout-icon'}>
                    <SC.ListItemIcon icon={item.icon} size="lg" onClick={() => navigationPath(item)} />
                </SC.ListItemLogoutIconContainer>
            </SC.ListItemLogoutContainer>
        </SC.NavigatorListItemConatainer>

        return (
            <SC.NavigatorListItemConatainer>
                <SC.MyProfileNavigatorListItem
                    className={'nav-item-container'}
                    key={item.id}
                    onClick={() => navigationPath(item)}
                    selected={item.url.split("/")[0] == status ? true : false}
                >
                    <SC.ListItemIcon icon={item.icon} size="xl" />
                    <div className={'nav-item-text'}>
                        <Message messageKey={item.id} defaultMessage={item.text} />
                    </div>
                </SC.MyProfileNavigatorListItem>
            </SC.NavigatorListItemConatainer>
        );

    };
    
    return (

        <SC.MyProfileNavigatorContainer>
            <SC.MyProfileNavigationList className={showNavi ? "show" : ''}>
                <Drawer
                    expanded={expanded}
                    position={"start"}
                    mode={"push"}
                    mini={true}
                    miniWidth={320}
                    items={myProfileNavigation(session.role, session.userId).map(
                        (item) => ({ ...item })
                    )}
                    item={drawerItemContainer}
                    width={320}
                    onSelect={(item) => navigationPath(item?.itemTarget?.props)}
                >
                    <DrawerContent>
                        <Outlet />
                    </DrawerContent>
                </Drawer>
            </SC.MyProfileNavigationList>
        </SC.MyProfileNavigatorContainer>
    );
};

export default memo(MyProfilePage);
