import { MyProfileNavigationType } from '@core-interface'
import { faPowerOff } from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarCheck, faFileCheck, faTooth, faTruckMedical, faUserDoctorMessage, faUserHair, faClock } from '@fortawesome/pro-light-svg-icons';
export const myProfileNavigation = (type = 2, id): Array<MyProfileNavigationType> => {

  if (type === 1) {
    return [
      // {
      //   text: 'My profile',
      //   id: 'myProfile',
      //   url: 'profile',
      // },
      {
        text: 'Dentist Profile',
        id: 'dentist_profile',
        url: `dentistProfile/${id}`,
        icon: faUserHair,
      },
      {
        text: 'My Chats',
        id: 'my_chats',
        url: `${process.env.REACT_APP_CHAT_URL}`,
        icon: faUserDoctorMessage,
      },
      {
        text: 'Emergency Requests',
        id: 'emergency_requests',
        url: 'emergencyRequests',
        icon: faTruckMedical,
      },
      {
        text: 'Treatment Requests',
        id: 'treatment_requests',
        url: 'treatmentRequests',
        icon: faTooth,
      },
      {
        text: 'Bookings',
        id: 'bookings',
        url: 'appointments',
        icon: faCalendarCheck,
      },
      // {
      //   text: 'Channel History',
      //   id: 'channelHistory',
      //   url: 'channelHistory',
      // },
      {
        text: 'Reviews',
        id: 'reviews',
        url: 'reviews',
        icon: faFileCheck,
      },
      {
        text: 'Booking Widget',
        id: 'booking_widget',
        url: 'bookingWidget',
        icon: faClock,
      },
      {
        text: 'Logout',
        id: 'logout',
        url: '',
        icon: faPowerOff,
      }
    ];
  } else {
    return [
      {
        text: 'My profile',
        id: 'my_profile',
        url: 'profile',
        icon: faUserHair,
      },
      {
        text: 'My Chats',
        id: 'my_chats',
        url: `${process.env.REACT_APP_CHAT_URL}`,
        icon: faUserDoctorMessage,
      },
      {
        text: 'Emergency Requests',
        id: 'emergency_requests',
        url: 'emergencyPatientRequests',
        icon: faTruckMedical,
      },
      {
        text: 'Treatment Requests',
        id: 'treatment_requests',
        url: 'treatmentPatientRequests',
        icon: faTooth,
      },
      {
        text: 'Bookings',
        id: 'bookings',
        url: 'appointments',
        icon: faCalendarCheck,
      },
      {
        text: 'Reviews',
        id: 'reviews',
        url: 'reviews',
        icon: faFileCheck,
      },
      {
        text: 'Logout',
        id: 'logout',
        url: '',
        icon: faPowerOff,
      }
    ];
  }

};
