import Cookies from 'js-cookie';
import { Session, LOGIN_STATUS } from "@core-interface";
import { USER_ROLES } from './authTypes';

export const ShadeColor = (col: string, amt: number) => {
  return (
    "#" +
    col
      .replace(/^#/, "")
      .replace(/../g, (col) =>
        (
          "0" + Math.min(255, Math.max(0, parseInt(col, 16) + amt)).toString(16)
        ).substr(-2)
      )
  );
};

export const logoutUser = () => {

  const logoutChannel = new BroadcastChannel('logoutChannel');
  logoutChannel.postMessage({ type: 'logout' });
  logoutChannel.close();
  localStorage.removeItem('u-in');
  localStorage.removeItem('u-ses');
  Cookies.remove('u-in', { domain: process.env.REACT_APP_DOMAIN_URL });
  Cookies.remove('u-ses', { domain: process.env.REACT_APP_DOMAIN_URL });
  window.open('/', '_self');
}

export const setSession = (session) => {

  if (session.role === USER_ROLES.PARTICIPANT)
    window.open(process.env.REACT_APP_CHAT_APP_NAVIGATE_URL, '_self');

  localStorage.setItem('u-in', JSON.stringify(session));
  localStorage.setItem('u-ses', session.accessToken);

  Cookies.set('u-in', JSON.stringify(session), { domain: process.env.REACT_APP_DOMAIN_URL,
     expires : session.expiresIn
     });
  Cookies.set('u-ses', session.accessToken, { domain: process.env.REACT_APP_DOMAIN_URL,
     expires : session.expiresIn
     });
}
export const updateSession = (value: any) => {
  const currentSesion = JSON.parse(Cookies.get('u-in'));
  if(value){
    const newSession = {...currentSesion, ...value}
    Cookies.set('u-in', JSON.stringify(newSession), { domain: process.env.REACT_APP_DOMAIN_URL,
      expires : currentSesion.expiresIn
      });
  }
}

export const getCookiesValue = (key: string, needDecrypt = false) => { return Cookies.get(key) };

export const setCookiesValue = (key: string, value: any, needEncrypt = false) => {
  try {
    let prepValue = value;
    localStorage.setItem(key, prepValue);
    Cookies.set(key, value, { domain: `${process.env.REACT_APP_DOMAIN_URL}` });
  } catch (e) {
    console.log("-------- storage setting error --------");
    console.log("value ", value);
    console.log(e);
  }
};

export const getSession = (): Session | null => {
  let str;
  str = localStorage.getItem('u-in');
  let session: Session | null = null;
  if (str)
    session = JSON.parse(str);
  return session;
}

export const loginUrl = () => {
  let url = `${process.env.REACT_APP_AUTH_ZERO_URL}`;
};

export const getAccessToken = () => Cookies.get('u-ses');

// const baseUrl = process.env.REACT_APP_BASE_URL;
// const redirectUrl = localStorage.getItem('redirectUrl');

// if (redirectUrl) {
//   url = url + `&redirect_uri=${baseUrl}${redirectUrl}`
//   localStorage.removeItem('redirectUrl')
// } else {
//   url = url + `&redirect_uri=${baseUrl}`
// }

export const profileInfoCreator = (data: any, role: number) => {
  switch (role) {
    case USER_ROLES.DENTIST:
      {
        return ({
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.EMail,
          Gender: data.Gender,
          PhoneNumber: data.BusinessPhone,
        })
      }
      break;
    case USER_ROLES.PATIENT:
      {
        return ({
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.EmailAddress,
          PhoneNumber: data.MobileNumber,
        })
      }
      break;

    default:
      return undefined;
      break;
  }
};

export const regionFinder = (): string => {
  const hostname = window.location.hostname;
  // const hostname = 'smylor.metroapps.online';
  return (hostname.split('.').pop());
};

export const maskedPhoneNumberCoverter = (value: string) => {
  const maskedInput: string = value
      .split('')
      .filter((char: any) => /\d|\+/.test(char))
      .join('');
  return maskedInput;
}