
import loadable from "@loadable/component";

const PatientProfileComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./patientProfile")
);
const ChatsComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./chats")
);
const DentistProfileComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./dentistProfile")
);
const AppointmentsComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./appointments")
);
const AppointmentViewComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./appoinmentCommon")
);
const ChannelHistoryComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./channelHistory")
);
const ReviewsComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./reviews")
);
const ReviewComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./patientReviewForm")
);
const EmergencyRequestsComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./emergencyRequests")
);
const TreatmentRequests = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./treatmentRequests")
);
const TreatmentRequest = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./treatmentRequest")
);
const EmergencyRequestComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./emergencyRequest")
);
const EmergencyPatientRequestsComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./emergencyPatientRequests")
);
const EmergencyPatientRequestComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./emergencyPatientRequest")
);
const ReviewListView = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./reviewCommon")
);
const TreatmentPatientRequestComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./treatmentPatientRequest")
);
const TreatmentPatientRequestsComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./treatmentPatientRequests")
);
const BookingWidgetComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____myprofile"*/ "./bookingWidget")
);

const MyProfileRoutes = () => {
  const routes = [
    {
      path: 'profile',
      element: <PatientProfileComponent />,
    },
    {
      path: 'dentistProfile/:id',
      element: <DentistProfileComponent />,
    },
    {
      path: 'chats',
      element: <ChatsComponent />,
    },
    {
      path: 'appointments',
      element: <AppointmentsComponent />,
    },
    {
      path: 'appointments/:id',
      element: <AppointmentViewComponent />,
    },
    {
      path: 'channelHistory',
      element: <ChannelHistoryComponent />,
    },
    {
      path: 'reviews',
      element: <ReviewsComponent />,
    },
    {
      path: 'reviews/:id',
      element: <ReviewListView />,
    },
    {
      path: 'reviews_new/:id',
      element: <ReviewComponent />,
    },
    {
      path: 'emergencyRequests',
      element: <EmergencyRequestsComponent />,
    },
    {
      path: 'treatmentRequests',
      element: <TreatmentRequests />,
    },
    {
      path: 'treatmentRequests/:id',
      element: <TreatmentRequest />,
    },
    {
      path: 'emergencyRequests/:id',
      element: <EmergencyRequestComponent />,
    },
    {
      path: 'emergencyPatientRequests',
      element: <EmergencyPatientRequestsComponent />,
    },
    {
      path: 'emergencyPatientRequests/:id',
      element: <EmergencyPatientRequestComponent />,
    },
    {
      path: 'treatmentPatientRequests',
      element: <TreatmentPatientRequestsComponent />,
    },
    {
      path: 'treatmentPatientRequests/:id',
      element: <TreatmentPatientRequestComponent />,
    },
    {
      path: 'bookingWidget',
      element: <BookingWidgetComponent />,
    }
  ];
  return routes;
};

export default MyProfileRoutes;